.insta-auth {
  height: 100%;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 2rem;
  }
}

.insta {
  font-size: calc(1rem + 1vw);
  color: var(--fore);

  &:hover,
  &:focus-visible,
  &:active {
    color: var(--fore);
  }

  div {
    background: var(--insta-gradient);
  }

  width: 100%;
  max-width: 16em;
}
