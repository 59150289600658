.container {
  --header-height: 80px;

  &.mark {
    --header-height: 99px;
  }

  --height: 100dvh;

  display: flex;
  flex-direction: column;
  color: var(--tertiary);
  height: var(--height);
  overflow: hidden;

  .header {
    height: var(--header-height);
  }

  main {
    height: calc(var(--height) - var(--header-height));
    border-top: 1px solid var(--tertiary);

    .mark& {
      border-top: unset;
    }
  }
}

:global(#ember-testing-container) .container {
  --height: 667px;
}
