.main {
  margin: auto;
}

.photos {
  margin: 0 auto 20px;
}

.text {
  color: var(--tertiary);
  text-align: center;
  font-size: 1.6rem;
  margin: auto;
}

.section {
  color: var(--tertiary);
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.picture {
  margin: auto;
  display: flex;
  justify-content: center;
  min-height: 0;
  overflow: visible;

  img {
    height: fit-content;
    max-width: 40dvh;
  }
}
