.cost-div {
  display: flex;

  --base-font-size: var(--cost-font-size);
}

.primary-secondary {
  --foreground-color: var(--primary);
  --shadow-color: var(--secondary);
}

.secondary-primary {
  --foreground-color: var(--secondary);
  --shadow-color: var(--primary);
}

.total {
  margin-bottom: calc(var(--base-font-size) * 0.3);
  white-space: nowrap;
}

.currency {
  margin: calc(var(--base-font-size) * 0.05) calc(var(--base-font-size) * 0.2) 0
    0;

  --offset-left: calc(var(--base-font-size) * 0.0375);
  --offset-bottom: calc(var(--base-font-size) * 0.075);

  height: fit-content;
  display: inline-block;
  padding: var(--offset-bottom) 0 0 var(--offset-left);
  color: var(--foreground-color);
  font-size: var(--base-font-size);
  font-weight: bold;
  position: relative;
  letter-spacing: calc(var(--base-font-size) * 0.1);
  -webkit-text-stroke: calc(var(--base-font-size) * 0.05) var(--tertiary);

  &::before {
    content: attr(data-content);
    position: absolute;
    bottom: var(--offset-bottom);
    left: calc(var(--offset-left) * -1);
    color: var(--shadow-color);
  }
}

.value {
  --offset-left: calc(var(--base-font-size) * 0.075);
  --offset-bottom: calc(var(--base-font-size) * 0.15);

  height: fit-content;
  display: inline-block;
  padding: var(--offset-bottom) 0 0 var(--offset-left);
  color: var(--foreground-color);
  font-size: calc(2 * var(--base-font-size));
  font-weight: bold;
  position: relative;
  letter-spacing: calc(var(--base-font-size) * 0.1);
  -webkit-text-stroke: calc(var(--base-font-size) * 0.1) var(--tertiary);

  &::before {
    content: attr(data-content);
    position: absolute;
    bottom: var(--offset-bottom);
    left: calc(var(--offset-left) * -1);
    color: var(--shadow-color);
  }
}

.per-month {
  font-weight: bold;
}
