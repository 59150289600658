@import url("modern-normalize/modern-normalize.css");

:root {
  --primary: #ffd96e;
  --secondary: #fc5e49;
  --tertiary: #011d49;
  --fore: #fff;
  --margin: 1rem;
  --insta-gradient: linear-gradient(
    90deg,
    #f9ce34 0%,
    #ee2a7b 51.56%,
    #6228d7 100%
  );
  --component-padding: 1.5rem;
  --color-success: #2f552f;
  --color-success-background: #e7f7e8;
  --color-error: #fc5e49;
  --color-error-background: rgb(247 231 231);
  --color-info: var(--tertiary);
  --color-info-background: #e0f2fe;
}

ul,
li {
  list-style: none;
}

p,
ul,
li,
a {
  margin: 0;
  padding: 0;
}

a,
a:hover,
a:active {
  color: var(--tertiary);
}

/* Accessibility */

:global(.sr-only) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: path(inset(50%));
  white-space: nowrap;
  border: 0;
}

/* Fluid Typography */

html {
  font-size: max(2dvh);

  @media (orientation: landscape) {
    font-size: min(2.2dvh, 2dvw); /* as get squarer make text smaller */
  }
}

html:has(:global(#ember-testing-container)) {
  font-size: calc(667px / 50);
}
