.loader {
  --diameter: 16px;
  --length: 48px;
  --duration: 2s;

  @media (prefers-reduced-motion) {
    --duration: 20s;
  }

  width: var(--length);
  height: var(--length);
  display: block;
  margin: 15px;
  position: relative;
  animation: rotation var(--duration) linear infinite;

  .container {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: var(--length);
    height: var(--length);
  }
}

.dot {
  position: absolute;
  left: 0;
  top: 0;
  width: var(--diameter);
  height: var(--diameter);
  border-radius: 50%;
  border: 1px solid var(--tertiary);
}

.one {
  transform: rotate(0deg);

  .dot {
    background-color: var(--fore);
  }
}

.two {
  transform: rotate(120deg);

  .dot {
    background-color: var(--primary);
  }
}

.three {
  transform: rotate(240deg);

  .dot {
    background-color: var(--secondary);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);

    /* transform: rotate(0deg); */
  }
}
