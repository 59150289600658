.insta {
  display: inline-block;
  font-size: calc(1rem + 1vw);
  color: var(--fore);

  &:hover,
  &:focus-visible,
  &:active {
    color: var(--fore);
  }

  @media (orientation: landscape) {
    font-size: 1rem;
    width: 18em;
  }

  div {
    background: var(--insta-gradient);
  }
}
