.form {
  color: var(--tertiary);
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;

  fieldset {
    display: flex;
    flex-direction: column;
    border: none;
  }

  legend {
    font-size: 2.8rem;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  input {
    padding: var(--component-padding);
    width: 100%;
    margin-bottom: 1rem;
  }

  .message {
    margin-bottom: 1rem;
    padding: 1rem;
    display: flex;
    gap: 0.6rem;
    justify-content: center;
    align-items: center;
  }

  .success {
    border: 1px solid;
    background-color: var(--color-success-background);
    color: var(--color-success);
  }

  .error {
    border: 1px solid;
    background-color: var(--color-error-background);
    color: var(--color-error);
  }

  .info {
    border: 1px solid;
    background-color: var(--color-info-background);
    color: var(--color-info);
  }

  .submit {
    font-size: calc(1rem + 1vw);
    margin-bottom: 1rem;
    color: var(--fore);
  }

  .submit > div {
    background: var(--secondary);
  }

  .agree {
    font-weight: 300;
  }
}
